import { Home } from "pages/Home";
import { SignIn } from "pages/SignIn";
import { PrivatePage } from "pages/privatePage"
import { useRoutes } from "react-router-dom";
import { SignUp } from "pages/SignUp";
import { Deliveries } from "pages/Deliveries";
import { DeliveryStatus } from "pages/DeliveryStatus";
import { Drivers } from "pages/Drivers/DriverPage";
import { ClientPage } from "pages/ClientPage";
import { BoxReturn } from "pages/BoxReturn";
import { Teams } from "pages/Drivers/TeamPage";
import { Punctuality } from "pages/Punctuality";
import { RoutePage } from "pages/RoutePage";
import { Priority } from "pages/PriorityPages";
import { BoxCount } from "pages/BoxCount";
import { ExportRoutes } from "pages/ExportRoutes";
import { SupportDrivers } from "pages/DriverStatus/SupportDriver";
import { UnavailableDrivers } from "pages/DriverStatus/UnavailableDriver";

const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <SignIn/>
    },
    {
      path: '/home',
      element: <PrivatePage children={<Home />}/>,
    },
    {
      path: '/signup',
      element: <PrivatePage children={<SignUp />}/>
    },
    {
      path: '/deliveries',
      element: <PrivatePage children={<Deliveries />}/>
    },
    {
      path: '/box-return',
      element: <PrivatePage children={<BoxReturn />}/>
    },
    {
      path: '/export-routes',
      element: <PrivatePage children={<ExportRoutes />}/>
    },
    {
      path: '/box-count',
      element: <PrivatePage children={<BoxCount/>}/>
    },
    {
      path: '/delivery-status',
      element: <PrivatePage children={<DeliveryStatus />}/>
    },
    {
      path: '/drivers',
      element: <PrivatePage children={<Drivers />}/>
    },
    {
      path: '/support-drivers',
      element: <PrivatePage children={<SupportDrivers />}/>
    },
    {
      path: '/unavailable-drivers',
      element: <PrivatePage children={<UnavailableDrivers />}/>
    },
    {
      path: '/teams',
      element: <PrivatePage children={<Teams />}/>
    },
    {
      path: '/clients',
      element: <PrivatePage children={<ClientPage />}/>
    },
    {
      path: '/driver-punctuality',
      element: <PrivatePage children={<Punctuality />}/>
    },
    {
      path: '/routes',
      element: <PrivatePage children={<RoutePage />}/>
    },
    {
      path: '/priority',
      element: <PrivatePage children={<Priority />}/>
    },
    {
      path: '*',
      element: <PrivatePage children={<Home />}/>
    }

  ])
}

export default Routes;
