import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

import SidebarComponent from "components/Sidebar";
import { Header } from "components/Header";
import { PageContainer } from "components/PageContainer";
import { DataTable, ColumnProps } from "components/Table";
import { Toolbar } from "components/Toolbar";
import CustomCheckBox from "components/checkbox";
import { FormTableLine } from "./FormTable";

import { Container, MainContainer, SidebarContainer } from "./styles";
import { routeService } from "services/routeService";
import { abacateService } from "services/abacateApi";
import { chunkArray } from "utils/chunkArray";
import { verifyDate } from "utils/verifyDate";

import { ExportRoutesResponse, IOrderRoute } from "interfaces/IWaybill";
import { IAgileRoute } from "interfaces/IAgileRouteResponse";

export const ExportRoutes = () => {
  const smallScreen = useMediaQuery({ maxWidth: 820 });
  const [data, setData] = useState<IAgileRoute[]>([]);
  const [filteredData, setFilteredData] = useState<IAgileRoute[]>([]);
  const [dataOrderRoute, setDataOrderRoute] = useState<IOrderRoute[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [responses, setResponses] = useState<ExportRoutesResponse | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [routes, orderRoute] = await Promise.all([
        routeService.getAgileRoutes(),
        abacateService.getOrderRoute({ scheduled_date: verifyDate() }),
      ]);

      if (routes.length === 0) {
        toast.warning("Não foram encontradas rotas para este dia.");
      }

      const filteredRoutes = routes.filter(({ depot }: IAgileRoute) => depot?.depotName !== "CD Compras");

      setData(filteredRoutes);
      setFilteredData(filteredRoutes);
      setDataOrderRoute(orderRoute);
    } catch {
      toast.error("Erro ao buscar dados. Por favor, tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = data.filter(({ vehicle, routeId }: IAgileRoute) =>
      vehicle?.driver?.name?.toLowerCase().includes(lowerCaseQuery) ||
      routeId.toString().includes(lowerCaseQuery) ||
      vehicle?.name?.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filtered);
  };

  const handleToggleSelected = (routeId: number) => {
    setSelectedRows(prev =>
      prev.includes(routeId) ? prev.filter(id => id !== routeId) : [...prev, routeId]
    );
  };

  const handleToggleAllSelected = () => {
    const newState = !isCheckedAll;
    setIsCheckedAll(newState);
    setSelectedRows(newState ? filteredData.map(({ routeId }: IAgileRoute) => routeId) : []);
  };

  const handleExportRoutes = async () => {
    toast.info("Aguarde, isso pode levar algum tempo...");
    setWaitingResponse(true);
    setResponses(null);

    try {
      const chunks = chunkArray(selectedRows, 3);
      for (const chunk of chunks) {
        const bodyList = chunk.map(routeId => data.find(route => route.routeId === routeId));
        const response = await routeService.exportRoutes(bodyList);
        setResponses(prev => ({ ...prev, ...response.responses }));
      }
      toast.success("Envio de rotas finalizado. Verifique o resultado.");
    } catch {
      toast.error("Houve um erro ao exportar as rotas. Por favor, tente novamente mais tarde.");
    } finally {
      setWaitingResponse(false);
      setSelectedRows([]);
      setIsCheckedAll(false);
    }
  };

  const defaultColumns: ColumnProps[] = [
    {
      name: <CustomCheckBox handleToggle={handleToggleAllSelected} align='center' checked={isCheckedAll === true} />,
      align: "center",
      minWidth: "80px"
    },
    {
      name: "Motorista",
      align: "left",
    },
    {
      name: "Rota",
      align: "center",
    },
    {
      name: "Centro de Distribuição",
      align: "center",
    },
    {
      name: "Data de Atendimento",
      align: "center",
    },
    {
      name: "Pedidos Enviados",
      minWidth: "120px",
      align: "center"
    },
    {
      name: "",
      minWidth: "40px",
      align: "center"
    }
  ]

  const smallScreenColumns: ColumnProps[] = [
    {
      name: <CustomCheckBox handleToggle={handleToggleAllSelected} align='center' checked={isCheckedAll === true} />,
      align: "center",
      minWidth: "80px"
    },
    {
      name: "Motorista",
      align: "left",
    },
    {
      name: "Rota",
      align: "center",
    },
    {
      name: "CD",
      align: "center",
    },
    {
      name: "Data",
      align: "center",
    },
    {
      name: "Pedidos",
      minWidth: "120px",
      align: "center"
    },
    {
      name: "",
      minWidth: "40px",
      align: "center"
    }
  ]

  let columns = smallScreen ? smallScreenColumns : defaultColumns

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <div className="pageContent">
        <Header />
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className="infos">
              <h3>Exportação de Rotas</h3>
              <Toolbar
                loadingState={loading}
                placeholder="Motorista, veículo ou rota"
                hideFilter
                singleButton
                onChangeSearch={handleSearch}
                extraButtons={[{
                  type: "filled",
                  text: `Exportar Rotas ${selectedRows.length ? `(${selectedRows.length})` : ""}`,
                  onClick: handleExportRoutes,
                  disableState: selectedRows.length === 0 || waitingResponse,
                  customWidth: "160px",
                  customMargin: "0 24px 0 0"
                }]}
              />
            </div>
            <DataTable loading={loading} columns={columns}>
              {filteredData
                .sort((a: IAgileRoute, b: IAgileRoute) => a.routeId - b.routeId)
                .map(element => (
                  <FormTableLine
                    key={element.routeId}
                    element={element}
                    handleToggleSelected={handleToggleSelected}
                    selectedRows={selectedRows}
                    waitingResponse={waitingResponse}
                    responses={responses}
                    dataOrderRoute={dataOrderRoute}
                  />
                ))}
            </DataTable>
            <div className="spacer" />
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  );
};
