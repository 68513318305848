import { BrowserRouter } from "react-router-dom";
import Routes from "routes/Routes";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/global";
import { defaultTheme } from "./styles/theme/default"
import { AppProvider } from "contexts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { api } from "services/api";
import { useLogout } from "hooks/useLogout";

export function App() {
  useEffect(() => {
    api.setFuncions({ useLogout });
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme} >
        <GlobalStyle />
        <ToastContainer />
        <AppProvider>
          <Routes />
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

