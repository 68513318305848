import SidebarComponent from 'components/Sidebar';
import saveAs from "file-saver";
import { Header } from 'components/Header';
import { Container, MainContainer, SidebarContainer } from './styles';
import { ColumnProps, DataTable } from "components/Table";
import { useEffect, useRef, useState } from "react";
import { PageContainer } from 'components/PageContainer'
import { Toolbar } from "components/Toolbar";
import { Popup } from "components/Popup";
import { ObservationForm } from "components/PopupForms/ObservationForm";
import { IBoxes } from 'interfaces/IBoxes';
import { FormTableLine } from './FormTable';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { dateToday } from 'utils/dateToday';
import { boxControlService } from 'services/boxControlService';
import { IDriver } from 'interfaces/IDriver';
import { BoxHistoryForm } from 'components/PopupForms/BoxHistoryForm';
import { ICheckboxOption } from 'components/checkbox';
import { ConfirmPopup } from './ConfirmChangeDriverPopup';
import { routeService } from 'services/routeService';
import { IDriverChangeHistory } from 'components/PopupForms/ShowDriverChangeHistory';


export interface IChangeDriverParams {
  waybillId: number;
  vehicleId: number;
  changedVehicleId: number | undefined;
  action: string;
  deliveryDate: string;
}

export interface IConfirmationPopupVisible {
  visible: boolean;
  route: IBoxes | null;
  existentRoute: IBoxes | null;
  onConfirm: () => void;
}

const defaultColumns: ColumnProps[] = [
  {
    name: "Nome",
    align: "left",
  },
  {
    name: "Rotas",
    align: "center",
  },
  {
    name: "Caixas",
    align: "center",
  },
  {
    name: "First Mile",
    align: "center"
  },
  {
    name: "Último Saldo",
    align: "center"
  },
  {
    name: "Centro de Distribuição",
    align: "center",
  },
  {
    name: "",
  }
]

const smallScreenColumns: ColumnProps[] = [
  {
    name: "Nome",
    align: "left",
  },
  {
    name: "Rotas",
    align: "center",
  },
  {
    name: "Caixas",
    align: "center",
  },
  {
    name: "First Mile",
    align: "center"
  },
  {
    name: "Último Saldo",
    align: "center"
  },
  {
    name: "CD",
    align: "center",
  },
  {
    name: "",
  }
]

export function BoxReturn() {
  const smallScreen = useMediaQuery({ maxWidth: 820 })
  const [popupVisible, setPopupVisible] = useState(false)
  const [formEdit, setFormEdit] = useState<IBoxes>({} as IBoxes)
  const [firstMileTeam, setFirstMileTeam] = useState<string[]>([])
  const [showNegativeBalanceOnly, setshowNegativeBalanceOnly] = useState(false);
  const [includeSupportDrivers, setIncludeSupportDrivers] = useState(true);
  const [data, setData] = useState<IBoxes[]>([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 30,
    search: '',
    notPaginated: true,
    includeSupportDrivers: true,
    startDeliveryDate: dateToday(),
    endDeliveryDate: dateToday(),
    deliveryDate: dateToday(),
  });
  const [HistoryformEdit, setHistoryFormEdit] = useState<IBoxes>({} as IBoxes)
  const [historyPopupVisible, setHistoryPopupVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const oldData = useRef<IBoxes[]>([])
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState<IConfirmationPopupVisible>({
    visible: false,
    route: null,
    existentRoute: null,
    onConfirm: () => { }
  })
  let columns = smallScreen ? smallScreenColumns : defaultColumns

  const handleToggleNegativeBalance = () => {
    if (loading) return;
    setshowNegativeBalanceOnly(!showNegativeBalanceOnly);
  };

  const handleToggleSupportDrivers = () => {
    if (loading) return;
    setIncludeSupportDrivers(!includeSupportDrivers);
    setParams({ ...params, includeSupportDrivers: !includeSupportDrivers });
  };

  const checkboxes: ICheckboxOption[] = [
    {
      id: 'negativeBalance',
      text: 'Saldos Negativos',
      handleToggle: handleToggleNegativeBalance,
      state: showNegativeBalanceOnly
    },
    {
      id: 'includeSupportDrivers',
      text: 'Motoristas de Suporte',
      handleToggle: handleToggleSupportDrivers,
      state: includeSupportDrivers
    }
  ];

  const submitFormBoxesSent = async (element: IBoxes) => {
    try {
      await boxControlService.submitForm(element)
      toast.success("Dados enviados com sucesso!")
    } catch {
      toast.error("Erro ao submeter os dados")
    }
  }

  const submitFormBoxesReturn = async (element: IBoxes) => {
    try {
      await boxControlService.createBoxReturn(element)
      toast.success("Dados enviados com sucesso!")
    } catch {
      toast.error("Erro ao submeter os dados")
    }
  }

  const updateData = (updatedElement: IBoxes, isOldData?: boolean) => {
    if (isOldData) {
      oldData.current = oldData.current.map((element: IBoxes) =>
        element.id === updatedElement.id ? updatedElement : element
      )
      return
    }

    setData((prevData: IBoxes[]) =>
      prevData.map((element: IBoxes) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const [response, balance, firstMileTeam, driverChangeHistory] = await Promise.all([
        boxControlService.getBoxesSent(params),
        boxControlService.getBoxesBalance(params),
        boxControlService.getFirstMileTeam(),
        routeService.getDriverChangeHistory(params)
      ]);

      const names = ['Sem seleção', ...firstMileTeam.results.map((driver: IDriver) => driver.name)];
      setFirstMileTeam(names);
      const dataPagination = response.results;
      const balanceData = balance.results;

      setLoading(false);

      const newData = joinData(dataPagination, balanceData, driverChangeHistory);
      setData(newData);
      oldData.current = newData;
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao buscar dados no sistema');
    }
  };

  const joinData = (dataPagination: IBoxes[], balanceData: IBoxes[], driverChangeHistoryData: IDriverChangeHistory[]): IBoxes[] => {
    if (!dataPagination || !Array.isArray(dataPagination) || !balanceData || !Array.isArray(balanceData)
        || !driverChangeHistoryData || !Array.isArray(driverChangeHistoryData)) {
      return [];
    }

    const combinedData: IBoxes[] = dataPagination.map((boxesData: IBoxes) => {
      const balance = balanceData.find((balance: IBoxes) => balance.vehicle?.id === boxesData.vehicle?.id);
      const driverChangeHistory = driverChangeHistoryData.filter((history: IDriverChangeHistory) => history.route === String(boxesData.route));
      return {
        ...boxesData,
        lastRoute: balance ? balance : null,
        driverChangeHistory: driverChangeHistory ? driverChangeHistory : undefined
      };
    });

    return combinedData;
  };

  const downloadFile = async () => {
    try {
      setLoading(true);
      const response = await boxControlService.saveFileBoxesReturn(params.startDeliveryDate, params.endDeliveryDate);
      const blob = new Blob([response], { type: 'application/ms-excel;charset=utf-8' });
      saveAs(blob, 'Retorno-de-Caixas.xlsx');
    } catch (e) {
      toast.error("Houve um erro ao tentar baixar a planilha");
    } finally {
      setLoading(false);
    }
  }

  const handleEdit = (data: IBoxes) => {
    setFormEdit(data)
    setPopupVisible(true)
  }

  const handleEditHistory = (data: IBoxes) => {
    setHistoryFormEdit(data)
    setHistoryPopupVisible(true)
  }

  const handleSaveComment = (comment: string) => {
    const newElement = { ...formEdit, returnComment: comment }
    boxControlService.updateComment(newElement)
      .then(e => {
        toast.success("Observação salva com sucesso!")
        fetchData()
      }).catch(e => {
        toast.error("Não foi possível salvar a observação")
      })
  }

  const updateDriver = async (params: IChangeDriverParams) => {
    try {
      toast.info("Aguarde...");

      setLoading(true);

      await routeService.putLineup(params);
      await routeService.updateLineup([params]);

      fetchData();

      toast.success("Motorista atualizado com sucesso!");
    } catch (error: any) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <Container>
      <Popup
        popupTitle='Editar Observação'
        open={popupVisible}
        setOpen={setPopupVisible}
        isEditPopup
        isObservation
        content={<ObservationForm data={formEdit} handleSave={handleSaveComment} />} />
      <Popup
        popupTitle='Saldo Histórico'
        open={historyPopupVisible}
        setOpen={setHistoryPopupVisible}
        isTablePopup
        confirmDelete
        content={<BoxHistoryForm vehicleId={HistoryformEdit?.vehicle?.id} />}
      />
      <ConfirmPopup
        setPopupVisible={setConfirmationPopupVisible}
        popupVisible={confirmationPopupVisible}
      />

      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Devolução de Caixas</h3>
              <Toolbar
                params={params}
                paramState={setParams}
                loadingState={loading}
                placeholder="Motorista, veículo ou rota"
                export
                onDownload={downloadFile}
                page='BoxReturn'
                checkboxes={checkboxes}
              />
            </div>
            <DataTable loading={loading} columns={columns}>
              {data
                .filter((element: IBoxes) => !showNegativeBalanceOnly ||
                  ((element.lastRoute?.boxesSent || 0) - (element.lastRoute?.boxesReturned || 0)) > 0)
                .sort((a: IBoxes, b: IBoxes) => a.route - b.route)
                .map((element: IBoxes) => (
                  <FormTableLine
                    key={element.id}
                    element={element}
                    onSubmitSent={submitFormBoxesSent}
                    onSubmitReturn={submitFormBoxesReturn}
                    handleEdit={handleEdit}
                    handleEditHistory={handleEditHistory}
                    firstMileTeam={firstMileTeam}
                    updateData={updateData}
                    oldData={oldData}
                    formLoading={formLoading}
                    setFormLoading={setFormLoading}
                    confirmationPopupVisible={confirmationPopupVisible}
                    setConfirmationPopupVisible={setConfirmationPopupVisible}
                    updateDriver={updateDriver}
                  />
                ))
              }
            </DataTable >
            <div className="spacer" />
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}
