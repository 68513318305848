import axios, { AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import cookies from 'js-cookie'

import { baseURL } from '../settings'

interface IApi {
  useLogout: () => void
}

export class ApiService {
  private useLogout: any = null
  private isAuthError = false

  public setFuncions({ useLogout }: IApi) {
    this.useLogout = useLogout
  }

  public instance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: cookies.get('authToken') && `Bearer mantega`,
    },
    timeout: 0,
  })
  interceptor = this.instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      let data: any = error.response?.data
      if (
        data.statusCode === 401 &&
        data.detail !==
          'No active account found with the given credentials'
      ) {
        if (!this.isAuthError) {
          this.isAuthError = true

          localStorage.setItem('expiredToken', 'true');

          this.useLogout()
        }
      }
      return Promise.reject(error)
    },
  )

  public get = async (
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    return this.instance
      .get(url, config)
      .then(x => {return x.data})
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data

        if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
        throw err
      })
  }

  public post = async (
    url: string,
    params?: any,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    return this.instance
      .post(url, params, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
        throw err
      })
  }

  public put = async (
    url: string,
    params?: any,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    return this.instance
      .put(url, params, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
        throw err
      })
  }

  public delete = async (
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    return this.instance
      .delete(url, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
        throw err
      })
  }

  public patch = async (
    url: string,
    params?: any,
    config?: AxiosRequestConfig,
  ): Promise<any> => {
    return this.instance
      .patch(url, params, config)
      .then(x => x.data)
      .catch(err => {
        if (err?.message === 'Network Error') throw new Error('Network Error')
        if (axios.isAxiosError(err)) throw err.response?.data
        if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
        throw err
      })
  }
}

export const api = new ApiService()
